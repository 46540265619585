import { pagesLinks } from "../common/links"
import commonData from "../../pages-data/common/commonData"

export default {
	title: "Придумайте новый пароль",
	formFields: {
		password: {
			name: "password",
			label: "Новый пароль",
			type: "password",
			placeholder: "",
		},
		passwordConfirm: {
			name: "passwordConfirm",
			label: "Введите пароль ещё раз",
			type: "password",
			placeholder: "",
		},
	},
	submitButtonText: "Подтвердить",
	passwordChangedMessage: "Пароль был успешно изменён. ",
	loginLink: {
		...pagesLinks.login,
		text: "Войти в систему",
	},
	errorData: {
		header: "Ошибка изменения пароля",
		description:
			"Запрос на изменения пароля прерван из-за проблем на сервере. Пожалуйста, попробуйте позже или обратитесь к нам для завершения процесса.",
		backButtonText: commonData.serverErrorScreen.buttonText,
		phone: commonData.phone,
		email: commonData.emailSupport,
		chatraGreetingsText:
			"Привет! Вижу, что произошла ошибка. Если исправили самостоятельно — отлично! Если нужна помощь — напишите мне, буду рад помочь!",
	},
}
