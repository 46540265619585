import React from "react"
import PropTypes from "prop-types"

import ExclamationTriangle from "../../assets/svg/exclamationTriangle.svg"

import ButtonFilled from "../../components/ButtonFilled"

import styles from "./styles.module.styl"

export default class ServerErrorScreen extends React.Component {
	constructor(props) {
		super(props)
	}

	componentDidMount = async () => {
		this.props.componentDidMountHandler()
	}

	render() {
		return (
			<div className={styles.serverErrorScreen}>
				<div className={styles.serverErrorScreen__logo}>
					<ExclamationTriangle />
				</div>

				<h1 className={styles.serverErrorScreen__header}>
					{this.props.header}
				</h1>

				<p className={styles.serverErrorScreen__description}>
					{this.props.description}
				</p>

				<ButtonFilled
					className={styles.serverErrorScreen__button}
					handler={this.props.button.handler}
				>
					{this.props.button.text}
				</ButtonFilled>

				<a
					href={`tel:${this.props.phone.value}`}
					className={styles.serverErrorScreen__phone}
				>
					{this.props.phone.text}
				</a>

				<a
					href={`mailto:${this.props.email}`}
					className={styles.serverErrorScreen__email}
				>
					{this.props.email}
				</a>
			</div>
		)
	}
}

ServerErrorScreen.propTypes = {
	// Обработчик, который должен сработать
	// сразу после монтирования компонента
	componentDidMountHandler: PropTypes.func.isRequired,
	// Заголовок экрана
	header: PropTypes.string.isRequired,
	// Текст экрана
	description: PropTypes.string.isRequired,
	// Текст и обработчик кнопки
	button: PropTypes.exact({
		text: PropTypes.string,
		handler: PropTypes.func,
	}).isRequired,
	// Текст и ссылка телефонного номера
	phone: PropTypes.exact({
		text: PropTypes.string,
		value: PropTypes.string,
	}).isRequired,
	// Адрес электронной почты
	email: PropTypes.string.isRequired,
}
