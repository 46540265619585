import React from "react"

import { convertUrlParamsToObject } from "../utils"

import { setNewPassword } from "../apiRequests/login"

import { NetError } from "../apiRequests/utils"

import { openChat } from "../apiRequests/chaport"

import AllSizedHelmet from "../components/AllSizedHelmet"
import SectionHeaderInLoginAndRegister from "../components/sections/SectionHeaderInLoginAndRegister"
import Clients from "../components/Clients"

import InputPasswordCommon from "../components/InputPasswordCommon"
import ButtonFilled from "../components/ButtonFilled"
import AdaptiveLink from "../components/AdaptiveLink"
import ServerErrorScreen from "../components/ServerErrorScreen"

import ExclamationIcon from "../assets/svg/exclamationIcon.svg"

import styles from "./styles/newPassword.module.styl"

import pageData from "../pages-data/newPassword"
import commonData from "../pages-data/common/loginAndRegistration"

export default class NewPassword extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			token: "",
			login: "",
			password: "",
			passwordConfirm: "",
			invalidMessage: "",
			isFetching: false,
			passwordChanged: false,
			netError: false,
		}
	}

	componentDidMount = async () => {
		const { token, login } = convertUrlParamsToObject(
			this.props.location.search
		)

		if (token && login) {
			this.setState({
				token,
				login,
			})
		}
		// Если в параметрах нет `token` и `login`, значит пользователь перешел не из письма
		// и его нужно заредиректить на страницу авторизации
		else {
			window.location.href = "/login/"
		}
	}

	formChangeHandler = (fieldName, value) => {
		this.setState({
			[fieldName]: value,
		})
	}

	setNetErrorState = state => {
		this.setState({
			netError: state,
		})
	}

	openChaportChat = () => {
		return openChat()
	}

	changePassword = async () => {
		this.setState({
			isFetching: true,
		})

		try {
			const response = await setNewPassword({
				login: this.state.login,
				token: this.state.token,
				password: this.state.password,
				passwordConfirm: this.state.passwordConfirm,
			})

			this.setState({
				passwordChanged: response.isValid,
				invalidMessage: response.invalidMessage || "",
			})
		} catch (error) {
			if (error instanceof NetError) {
				this.setNetErrorState(true)
			}
		} finally {
			this.setState({
				isFetching: false,
			})
		}
	}

	renderFormField = props => (
		<div className={styles.newPassword__formField}>
			<label
				htmlFor={props.field.name}
				className={styles.newPassword__formFieldLabel}
			>
				{props.field.label}
			</label>

			<InputPasswordCommon
				extraProps={{
					id: props.field.name,
					autoFocus: props.autoFocus,
					type: props.field.type,
				}}
				invalid={this.state.invalidMessage}
				placeholder={props.field.placeholder}
				value={this.state[props.field.name]}
				changeHandler={value => this.formChangeHandler(props.field.name, value)}
			/>
		</div>
	)

	render() {
		const renderForm = () => {
			return (
				<div className={styles.newPassword__content}>
					<h1 className={styles.newPassword__header}>{pageData.title}</h1>

					<form>
						{/* Поле "Пароль" */}
						{this.renderFormField({
							field: pageData.formFields.password,
							autoFocus: true,
						})}

						{/* Поле "Подтверждение пароля" */}
						{this.renderFormField({
							field: pageData.formFields.passwordConfirm,
						})}
					</form>

					{this.state.invalidMessage && (
						<div className={styles.newPassword__invalidMessage}>
							<ExclamationIcon />
							{this.state.invalidMessage}
						</div>
					)}

					<ButtonFilled
						colorType="blue"
						className={styles.newPassword__submitButton}
						disabled={this.state.isFetching}
						handler={this.changePassword}
					>
						{pageData.submitButtonText}
					</ButtonFilled>

					{this.state.passwordChanged && (
						<p className={styles.newPassword__passwordChanged}>
							{pageData.passwordChangedMessage}
							<AdaptiveLink
								href={pageData.loginLink.href}
								useGatsbyLink={pageData.loginLink.useGatsbyLink}
							>
								{pageData.loginLink.text}
							</AdaptiveLink>
						</p>
					)}
				</div>
			)
		}

		return (
			<main className={styles.newPassword}>
				<AllSizedHelmet>
					<meta
						name="viewport"
						content="width=device-width, initial-scale=1, maximum-scale=5"
					/>
					<meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
					<meta http-equiv="ScreenOrientation" content="autoRotate:disabled" />

					<meta property="og:url" content="https://quickresto.ru/" />
					<meta property="og:type" content="website" />
					<link rel="canonical" href="https://quickresto.ru/" />
				</AllSizedHelmet>

				<SectionHeaderInLoginAndRegister
					logoLinkClassName={styles.newPassword__headerLogoLink}
				>
					<AdaptiveLink
						className={styles.newPassword__registrationPageLink}
						href={commonData.header.registrationPageLink.href}
						useGatsbyLink={commonData.header.registrationPageLink.useGatsbyLink}
					>
						{commonData.header.registrationPageLink.text}
					</AdaptiveLink>
				</SectionHeaderInLoginAndRegister>

				{this.state.netError ? (
					<ServerErrorScreen
						componentDidMountHandler={this.openChaportChat()}
						header={pageData.errorData.header}
						description={pageData.errorData.description}
						button={{
							text: pageData.errorData.backButtonText,
							handler: () => {
								this.setNetErrorState(false)
							},
						}}
						phone={pageData.errorData.phone}
						email={pageData.errorData.email}
					/>
				) : (
					renderForm()
				)}

				<Clients className={styles.newPassword__clients} />
			</main>
		)
	}
}
