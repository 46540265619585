import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import styles from "./styles.module.styl"

const IMAGES_ALTS = {
	vigorousDay: "Кофейня Бодрый день",
	madCafe: "Кофебар Mad Espresso Team",
	poke: "Кафе Poke House",
	metallurgist: "ХК «Металлург» (Магнитогорск)",
	mstu: "МГТУ им. Н.Э.Баумана",
	miami: "Крафтовый кофе-бар MyYummy",
}

export default function Clients(props) {
	return (
		<StaticQuery
			query={graphql`
				query {
					vigorousDay: file(relativePath: { eq: "vigorousday.png" }) {
						childImageSharp {
							gatsbyImageData(placeholder: NONE, width: 79)
						}
					}
					madCafe: file(relativePath: { eq: "madcafe.png" }) {
						childImageSharp {
							gatsbyImageData(placeholder: NONE, width: 55)
						}
					}
					poke: file(relativePath: { eq: "poke.png" }) {
						childImageSharp {
							gatsbyImageData(placeholder: NONE, width: 55)
						}
					}
					metallurgist: file(relativePath: { eq: "metallurgist.png" }) {
						childImageSharp {
							gatsbyImageData(placeholder: NONE, width: 54)
						}
					}
					mstu: file(relativePath: { eq: "mstu.png" }) {
						childImageSharp {
							gatsbyImageData(placeholder: NONE, width: 45)
						}
					}
					miami: file(relativePath: { eq: "miami.png" }) {
						childImageSharp {
							gatsbyImageData(placeholder: NONE, width: 77)
						}
					}
				}
			`}
			render={data => {
				const img = Object.keys(data).map(key => (
					<GatsbyImage
						key={key}
						// style = {
						//   {
						//     minWidth: data[key].childImageSharp.fluid.presentationWidth,
						//     maxHeight: data[key].childImageSharp.fluid.presentationHeight
						//   }
						// }
						alt={IMAGES_ALTS[key]}
						image={data[key].childImageSharp.gatsbyImageData}
					/>
				))

				return (
					<div
						className={`
            ${styles.clients__wrap}
            ${props.className || ""}
            `}
					>
						<div className={styles.clients__content}>{img}</div>
					</div>
				)
			}}
		/>
	)
}
