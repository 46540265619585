import React, { useState } from "react"

import InputCommon from "../../components/InputCommon"

import OpenedEye from "../../assets/svg/password-show.svg"
import ClosedEye from "../../assets/svg/password-hide.svg"

import styles from "./styles.module.styl"

const InputPasswordCommon = props => {
	const [displayPassword, setDisplayPassword] = useState(false)

	return (
		<div className={styles.inputPassword__wrapInput}>
			<InputCommon
				className={`
          ${styles.inputPassword__input}
          ${props.className || ""}
        `}
				invalid={props.invalid}
				extraProps={{
					...props.extraProps,
					type: displayPassword ? "text" : "password",
				}}
				disabled={props.disabled}
				placeholder={props.placeholder}
				value={props.value}
				changeHandler={props.changeHandler}
			/>
			{displayPassword ? (
				<OpenedEye onClick={() => setDisplayPassword(false)} />
			) : (
				<ClosedEye onClick={() => setDisplayPassword(true)} />
			)}
		</div>
	)
}
export default InputPasswordCommon
