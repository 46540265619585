import React from 'react'

import Arrow from '../../../assets/svg/arrow.svg'
import Logotype from '../../../assets/svg/logotype.svg'

import AdaptiveLink from '../../../components/AdaptiveLink'

import styles from "./styles.module.styl"

import commonData from '../../../pages-data/common/loginAndRegistration'



export default function SectionHeaderInLoginAndRegister(props) {
  return (
    <div
      className={`
        ${styles.header}
        ${props.className || ''}
      `}
    >

      <AdaptiveLink
        className={styles.header__indexPageLink}
        href={commonData.header.indexPageLink.href}
        useGatsbyLink={commonData.header.indexPageLink.useGatsbyLink}
      >
        <Arrow />
        {commonData.header.indexPageLink.text}
      </AdaptiveLink>

      <AdaptiveLink
        className={`
          ${styles.header____logoLink}
          ${props.logoLinkClassName || ''}
        `}
        href={commonData.header.indexPageLink.href}
        useGatsbyLink={commonData.header.loginPageLink.useGatsbyLink}
      >
        <Logotype />
      </AdaptiveLink>

      <div className={styles.header__childrenContainer}>
        {props.children}
      </div>

    </div>
  )
}
