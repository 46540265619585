import { pagesLinks } from "./links"

export default {
	header: {
		indexPageLink: {
			...pagesLinks.index,
			text: "Вернуться на сайт",
		},

		loginPageLink: {
			...pagesLinks.login,
			linkPrefixText: "Есть аккаунт?",
		},

		registrationPageLink: {
			...pagesLinks.registration,
			// Текст не стандартный
			text: "Регистрация",
		},
	},
}
